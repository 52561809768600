import React from "react"
import Layout from "./../components/layouts/Default"

import Header from "./../components/common/Header"
import { graphql } from "gatsby"
import Card from "./../components/common/Card"
import Reviews from "./../components/Reviews"

export default function Page({ data, location }) {
  return (
    <Layout
      title="Unsere Versicherungen bei Dierda Finanzdienstleistungen"
      description="Eine Erkrankung oder ein Unfall kann für jeden einzelnen schnell zu
      einem Leben am Rande des Existenzminimums führen. Wir finden für Dich
      die passende Versicherung, die greift, sobald es darauf ankommt."
      location={location}
      imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
      imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
      imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
      imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
    >
      <Header
        link="#angebote"
        linktext="Zum Angebot"
        image={data.header}
        alt="Älterer Herr der einen Helm anzieht"
      >
        <h1 className="mt-1 text-2xl font-extrabold leading-relaxed text-gray-900 sm:text-3xl lg:text-4xl ">
          <span className="text-blue-500">Versicherungen</span>
        </h1>

        <p className="prose prose-lg text-gray-700 ">
          Eine Erkrankung oder ein Unfall kann für jeden einzelnen schnell zu
          einem Leben am Rande des Existenzminimums führen. Wir finden für Dich
          die passende Versicherung, die greift, sobald es darauf ankommt.
        </p>
      </Header>
      <div className="container mx-auto my-6">
        <div className="relative px-4 pt-16 pb-20 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3" />
          </div>
          <div className="relative mx-auto max-w-7xl">
            <div className="text-center" id="angebote">
              <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Unsere Versicherungsangebote
              </h2>
              <p className="max-w-2xl mx-auto mt-3 text-xl leading-7 text-gray-700 sm:mt-4">
                Nachfolgend findest Du eine Auswahl unserer
                Versicherungs-Leistungen.
              </p>
            </div>
            <div className="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
              <Card
                image={data.bu}
                title="Berufs­unfähigkeits­versicherung"
                link="/versicherungen/berufsunfaehigkeitsversicherung/"
                subtitle="Versicherungen"
                sublink="/versicherungen/"
                text="Die Dienst- und Berufs­unfähigkeits­versicherung als individueller Schutz für jeden Erwerbstätigen."
              />

              <Card
                image={data.alter}
                title="Altersvorsorge"
                link="/versicherungen/altersvorsorge/"
                subtitle="Versicherungen"
                sublink="/versicherungen/"
                text="Deine sichere Altersvorsorge: Genieße den Ruhestand in der Sonne."
              />
            </div>
          </div>
        </div>
        <Reviews />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Versicherungen {
    site {
      siteMetadata {
        siteUrl
      }
    }
    
    header: file(relativePath: {eq: "headers/dierda_versicherungen_header.jpg"}) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }

    seoImage: file(
      relativePath: {
        eq: "headers/dierda_versicherungen_header.jpg"
      }
    ) {
      facebook: childImageSharp {
        gatsbyImageData(height: 630, width: 1200)
      }
      twitter: childImageSharp {
        gatsbyImageData(aspectRatio: 1)
      }
    }


    bu: file(
      relativePath: {
        eq: "headers/dierda_berufsunfaehigkeitsversicherung_header.jpg"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    alter: file(
      relativePath: { eq: "headers/dierda_altersvorsorge_header.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
